<template>
  <div
    class="unselect"
    @click="kktLogin"
    style="height: 52px;"
    :style="$route.path === '/signup' ? 'width: 100%;' : 'width: 52px;'"
  >
    <div class="kakao-wrapper body2-medium" v-if="$route.path === '/signup'">
      <img src="/static/icon/ic_kakao.svg" />
      <div>빠른 가입</div>
    </div>
    <div v-else>
      <img class="circle_img" src="/static/icon/ic_kakao_circle.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BtnSigninKakao',
  methods: {
    kktLogin() {
      // test
      // const url = 'http://localhost:8001/auth/user/signin/kakao/platformfy';
      const url = encodeURIComponent('https://master-dev.launchpack.co.kr:1111/auth/user/signin/kakao/platformfy');
      location.href = `https://kauth.kakao.com/oauth/authorize?client_id=54db7ca06bd6b6921f3dd5a32683cd81&redirect_uri=${url}&response_type=code`;
    },
  },
};
</script>

<style lang="stylus" scoped>
.kakao-wrapper
  display flex
  align-items center
  justify-content center
  gap 10px
  border-radius 50px
  background-color #FAE64C
  color #242428
  padding 10px 20px
  height 52px
  width 100%
.circle_img
  width 52px
  height 52px
</style>
